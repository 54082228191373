#description {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-weight: 490;
    margin-top: -80px;
    padding-top: 100px;
}
  
.description-content {
    color: #454545;
}
