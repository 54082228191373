.left-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0px 40px 40px; /* Top and bottom padding 40px, right and left padding 40px */
  height: calc(100% - 80px);
  position: fixed;
  width: 400px; /* Set this to the desired width */
}
.left-content h1 {
  margin-top: -10px; /* Remove top margin */
  margin-bottom: 0px;
  font-size: 60px;
}

.left-content p {
  margin-top: -5px;
  margin-bottom: auto;
  font-size: 15px;
}

.left-content nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -250px;
}

.section {
  margin-bottom: 1px; /* You can adjust this value to your needs */
}

.tiny-gap {
  width: 0.1px;
}

.left-content ul {
  list-style-type: none;
  padding-left: 0;
}

.left-content li {
  margin-bottom: 15px;
  position: relative; /* Add position relative for the line effect */
}
.left-content li a {
  text-decoration: none;
  color: black;
  position: relative;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Sans-serif fonts are usually cleaner */
  font-weight: 600; 
  font-size: 16px; /* Adjust size to preference */
}

.left-content li a:hover {
  color: #5aadff; /* Blue color on hover */
  cursor: pointer;
}

.left-content li a::before {
  content: '';
  display: block;
  height: 2px;
  background-color: #5aadff;
  width: 0;
  transition: width 0.3s ease; /* Transition effect */
  position: absolute;
  bottom: 0;
  left: 0;
}



.left-content li a:hover,
.left-content li a.active {
  color: #5aadff; /* Blue color when active or hovered */
  background-clip: content-box; /* Clip the background to the content area */
}

.left-content li a.active::before,
.left-content li a:hover::before {
  width: 100%; /* Line gets longer */
}


.left-content li a.active::before {
  transition: width 0.3s ease; /* Same transition when becoming active */
}

.left-content li a:hover::before {
  width: 100%; /* Line gets longer on hover */
}


.social-links {
  display: flex;
  justify-content: flex-start;
  margin-top: auto; /* Pushes social links to the bottom */
  margin-bottom: 40px; /* Bottom margin to match top padding */
  gap: 10px;
}

.social-links a {
  margin-right: 10px;
  color: #333;
  margin-left: 0; /* Ensure no left margin */
}

.social-links a:hover {
  color: #5aadff;
}


.social-links i {
  font-size: 35px;
}
