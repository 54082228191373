#experience{
    max-height: 95vh;
}

.experience-section {
    display: flex;
    flex-direction: row; 
    align-items: flex-start; 
    margin-bottom: 0px; 
    padding: 10px 15px 0px 20px; 
    margin-left: -10px; 
    margin-right: -10px; 
    cursor: pointer; 
    transition: background-color 0.3s ease;
    border-radius: 8px;
    box-sizing: border-box;
}

.experience-section-link {
    text-decoration: none;
    color: inherit;
}
    

.experience-section:hover {
    transform: scale(1.02); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    background-color: rgba(200, 200, 200, 0.1); 
}
.experience-section:hover .experience-item h3 {
    color: #5aadff; 
}
  


  .link-button {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    margin-left: -1px;
    font-size: 12px; 
    transition: transform 0.3s ease; 
  }
  
  .experience-section:hover .link-button {
    transform: translate(5px, -5px); 
  }
  
  
  .timeline {
    width: 25%;
  }
  
  .timeline p {
    font-size: 14px;
    margin: 2px 0px;
    color: #6a6a6a; 
    font-family: 'Roboto', sans-serif;
}

  
  .experience-details {
    width: 70%;
    margin-left: 5px;
  }
  
  .experience-item {
    margin-bottom: 20px;
  }

  .experience-item h3 {
    margin: 0px;
    font-size: 17px;
    font-weight: bold;
  }
  
  .experience-item p {
    margin: 3px -23px 6px 0px;
    font-size: 14px;
    color: #454545;
    font-family: 'Roboto', sans-serif;
    font-weight: 520;
  }
  
  
  
  .badges {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    gap: 6px; 
    size: 10px;
  }
  
  .badge {
    padding: 4px 8px;
    font-size: 12px;
    border: 0px solid #ccc;
    font-family: 'Roboto', sans-serif;
    font-weight: 560;
    background-color: rgba(0, 123, 255, 0.1);
    color: #5aadff;
    border-radius: 15px;
  }
  .resume-link {
    text-align: left;
    margin-top: 2px;
  }
  
  .resume-link a {
    color: #007bff;
    text-decoration: none;
    opacity: 0.7; 
    transition: opacity 0.3s ease; 
  }
  
  .resume-link a:hover {
    opacity: 1;
  }
  
  
  