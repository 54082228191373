.projects{
    margin-bottom: 25px;
}

.project-section {
    display: flex;
    flex-direction: row; 
    align-items: flex-start;
    margin-bottom: 0px; 
    padding: 10px 15px 0px 20px; 
    margin-left: -10px; 
    margin-right: -10px;
    cursor: pointer; 
    transition: background-color 0.3s ease; 
    border-radius: 8px;
    box-sizing: border-box;
  }

  .project-section-link {
    text-decoration: none;
    color: inherit;
}
    

.project-section:hover {
    transform: scale(1.02); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    background-color: rgba(200, 200, 200, 0.1); 
}
.project-section:hover .experience-item h3 {
    color: #5aadff;
}
  


  .link-buttonp {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    margin-left: 3px;
    font-size: 12px;
    transition: transform 0.3s ease;
  }
  
  .project-section:hover .link-buttonp {
    transform: translate(5px, -5px);
  }

  .project-section:hover .project-item h3 {
    color: #5aadff; 
}
  

  .project-image {
    width: 20%;
    max-width: 100%;
    padding: 0;
    margin-right: 20px;
    display: block;
  }
  
  .project-image img {
    width: 100%;
    border-radius: 8px;
    margin: 7px 0 0 5px;
    border: 1px solid transparent;
    transition: border-color 0.3s ease;
  }
  
  
  .project-section:hover .project-image img {
    border-color: #5aadff;
  }
  
  .project-details {
    width: 70%; 
    margin-left: 15px;
  }
  
  
  .project-item {
    margin-bottom: 20px;
  }

  .project-item h3 {
    margin: 0px;
    font-size: 17px; 
    font-weight: bold;
  }
  
  .project-item p {
    margin: 3px -23px 6px 0px;
    font-size: 14px;
    color: #454545;
    font-family: 'Roboto', sans-serif;
    font-weight: 520;
  }
  
  
  .badges {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    gap: 6px; 
    size: 10px;
  }
  
  .badge {
    padding: 3px 6px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 7px;
  }

  .github-link {
    text-align: left;
    margin-top: 2px;
  }
  
  .github-link a {
    color: #5aadff;
    text-decoration: none;
    opacity: 0.7; 
    transition: opacity 0.3s ease; 
  }
  
  .github-link a:hover {
    opacity: 1;
  }