body {
  font-family: 'Noto Sans', sans-serif;
  color: #1b1b1b;
  line-height: 1.6;
  margin: 0;
  overflow-x: hidden;
  background-color: #eaf4ff;
}

.layout {
  display: flex;
}

.container {
  width: 80%;
  margin: 0 auto; 
  display: flex;
}

.left-content {
  width: 40%; 
  padding: 40px;
  box-sizing: border-box;
  position: fixed;
  height: 100%;
  text-align: left;
  overflow-y: hidden;
}

.right-content {
  width: 60%;
  margin-left: 45%;
  padding: 55px 40px 80px 40px;
  box-sizing: border-box;
  min-height: 100vh;
  
}

#description, #experience, #projects {
  margin: 40px auto;
  max-width: 800px;
}

.experience-item, .project-item {
  margin-bottom: 20px;
}

h2 {
  color: #000000;
  margin-bottom: 15px;
}

h3 {
  color: #000000;
  margin-bottom: 10px;
}

ul{
  list-style: none;
  padding: none;
}

a{
  text-decoration: none;
}


